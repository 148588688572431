import styled from "styled-components";
import Drawer from "react-modern-drawer";
import {useEffect, useState} from "react";
import {collection, db, doc, getDoc} from "../../service/auth/firebase";
import {useTheme} from "@table-library/react-table-library/theme";
import {getTheme} from "@table-library/react-table-library/baseline";
import {numberToCommaString} from "../../service/convert";
import {CompactTable} from "@table-library/react-table-library/compact";
import {Title} from "../title";
import Spacer from "../spacer";

const MasterDetailButton = ({masters, tableData, index}) => {
    const [isOpen, setIsOpen] = useState(false)
    const [data, setData] = useState({
        nodes: [

        ]
    })


    const theme = useTheme([getTheme(),
        {
            HeaderRow: `
             .th {
                text-align: center
             }
            `,
            Table: `
             --data-table-library_grid-template-columns: repeat(5, 1fr);
             text-align: center;
            `,
            BaseCell: `
              padding: 12px 0px;
            `
        }
    ])

    const columns = [
        {label: '성함', renderCell: (item) => item.name},
        {label: '계좌번호', renderCell: (item) => item.account},
        {label: '지급액', renderCell: (item) => item.paymentAmount},
        {label: '정산금액(차인지급액)', renderCell: (item) => item.settlementAmount},
        {label: '지급여부', renderCell: (item) => item.masterPayment},
    ];

    const toggleDrawer = () => {
        setIsOpen(!isOpen)
    }

    const onClickCompleteSettlement = async () => {

    }



    useEffect(() => {
        if(isOpen&& masters &&tableData.length >0){
            const docRef = doc(collection(db, 'users'), masters[0])
            getDoc(docRef).then((doc) => {
                setData({
                    nodes: [
                        {
                            name: doc.data().displayName,
                            account: `${doc.data().accountBank} ${doc.data().accountNumber}`,
                            paymentAmount: numberToCommaString(Math.round(tableData[index].paymentAmount)),
                            settlementAmount: numberToCommaString(Math.round(tableData[index].settlementAmount)),
                            masterPayment: !tableData[index].masterPayment?"아니요":"예"
                        }
                    ]
                })
            })
        }
    }, [isOpen]);

    return (
        <>
        <Button onClick={toggleDrawer}>
            상세보기
        </Button>
            <Drawer
                open={isOpen}
                direction={'right'}
                size={"95vw"}
                onClose={toggleDrawer}
                lockBackgroundScroll={true}
            >
                <Padding>
                    <Title>마스터 정보</Title>
                    <Spacer height={40}/>
                    <CompactTable
                    columns={columns}
                    data={data}
                    theme={theme}
                    layout={{custom: true}}
                />
                <Spacer height={40}/>
                    {tableData[index] && <SettlementButton onClick={onClickCompleteSettlement} complete={tableData[index].masterPayment}>{tableData[index].masterPayment?"지급완료":"정산금액 지급완료"}</SettlementButton>}
                </Padding>


            </Drawer>
        </>
    )
}

const Button = styled.button`
    background-color: #07CA08;
  border: none;
  border-radius: 4px;
  padding: 8px;
  color: white;
  cursor: pointer;
`

const Padding = styled.div`
    padding: 3vw;
`


const SettlementButton = styled.button`
    background-color: ${props => props.complete === true ?"red": "#07CA08"};
  padding: 1vw 5vw;
  border-radius: 8px;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
  
`


export default MasterDetailButton