import styled from "styled-components";
import {useEffect, useState} from "react";
import {collection, db, doc, getDoc} from "../../service/auth/firebase";
import DrawerButton from "../buttons/drawerButton";
import Spacer from "../spacer";
import {Title} from "../title";
import MasterDrawer from "../drawers/masterDrawer";

const MasterCard = ({uid, show = false}) => {
    const [data, setData] = useState({})
    const {
        displayName,
        photoUrl
    } = data

    useEffect(() => {
        if (show) {
            const ref = doc(collection(db, 'users'), uid)
            getDoc(ref).then((doc) => {


                setData(
                    doc.data()
                )
            })
        }
    }, []);


    return (
        <>
            {data && <DrawerButton
                width={"80vw"}
                buttonChildren={<Card>
                    <Photo src={photoUrl !== ""?photoUrl:"/images/logo.png"}></Photo>
                    <Spacer height={20}/>
                    <DisplayName>{displayName}</DisplayName>
            </Card>}
                // drawerChildren={<InfoContainer>
                //     <PhotoContainer>
                //         <Photo src={photoUrl!==""?photoUrl:"/images/logo.png"}/>
                //         <Spacer height={20}/>
                //         <Title>기사명</Title>
                //     </PhotoContainer>
                //
                //     <Text>{displayName}</Text>
                //     <Spacer height={12}/>
                //
                // </InfoContainer>}
                drawerChildren={<MasterDrawer data={data} uid={uid}/>}
            />}

        </>
    )
}

const Card = styled.div`
  width: 100%;
  height: 180px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
`

const Photo = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 100px;
  box-shadow: rgba(0, 0, 0, 0.05) 0 6px 24px 0, rgba(0, 0, 0, 0.08) 0 0 0 1px;
  background-size: cover;
`

const DisplayName = styled.div`
  font-size: 17px;
  font-weight: bold;
  text-overflow: ellipsis;
`


export default MasterCard

