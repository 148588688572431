import styled from "styled-components";
import {IoAdd} from "react-icons/io5";

const CreateButton = ({onClick}) => {
    return (
        <ButtonContainer onClick={onClick}>
            <AddIcon/>
        </ButtonContainer>
    )
}


const ButtonContainer = styled.button`
  width: 40px;
  height: 40px;
  border-radius: 4px;
  background-color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
`


const AddIcon = styled(IoAdd)`
  font-size: 20px;
  color: grey;
`

export default CreateButton