import Spacer from "../spacer";
import {FiLogOut} from "react-icons/fi";
import styled from "styled-components";
import {Link} from "react-router-dom";

const DesktopHeader = ({onClickLogoutButton, pathname}) => {
    return (
        <DashboardNavigation>
            <DashboardHeaderLogo src={"/images/logo.png"}/>
            <Spacer height={60}/>
            <NavigationLink selected={pathname === "/dashboard"} to={''}>대시보드</NavigationLink>
            <NavigationLink selected={pathname.includes("progress")} to={'progress'}>작업</NavigationLink>
            <NavigationLink selected={pathname.includes("master")} to={'master'}>기사</NavigationLink>
            <NavigationLink selected={pathname.includes("calculate")} to={'calculate'}>정산</NavigationLink>
            <LogoutButton onClick={onClickLogoutButton}>
                <FiLogOut/>
            </LogoutButton>
            <Spacer height={20}/>
        </DashboardNavigation>
    )
}


const LogoutButton = styled.div`
  color: ${props => props.selected ? "#07ca03" : "grey"};
  font-size: 24px;
  width: 100%;
  height: 60px;
  margin-top: 8px;
  margin-bottom: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    font-weight: bold;
    color: #07ca03;
  }
`

const DashboardHeaderLogo = styled.img`
  margin-top: 20px;
  width: 60px;
  @media (max-width: 800px) {
    margin-top: 0;
  }
`

const DashboardNavigation = styled.div`
  width: 120px;
  height: 100vh;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: scroll;
  @media (max-width: 800px) {
    display: none;
  }
`

const NavigationLink = styled(Link)`
  color: ${props => props.selected ? "#07ca03" : "grey"};
  font-size: 16px;
  width: 100%;
  height: 60px;
  margin-top: 8px;
  margin-bottom: 8px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    font-weight: bold;
    color: #07ca03;
  }
`

export default DesktopHeader


