import styled from "styled-components";

const CategoryButton = ({selected, onClick, children}) => {
    return (
        <Button onClick={onClick} selected={selected}>
            {children}
        </Button>
    )
}

const Button = styled.button`
  border: none;
  box-shadow: rgba(50, 50, 93, 0.25) 0 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border-radius: 4px;
  background-color: ${props => props.selected?"#07C801":"white"};
  color: ${props => props.selected?"white":"black"};
  font-size: 20px;
  padding: 20px;
  min-width: 120px;
  cursor: pointer;

`


export default CategoryButton