import MasterCreateBoard from "../../components/boards/masterCreateBoard";
import {Container} from "../../components/containers/container";

const MasterCreate = () => {
    return (
        <Container>
            <MasterCreateBoard/>
        </Container>
    )
}

export default MasterCreate