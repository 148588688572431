
import {Title} from "../title";
import Spacer from "../spacer";
import MasterTable from "../tables/masterTable";
import CreateButton from "../buttons/createButton";
import SearchInput from "../inputs/searchInput";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {collection, db, getDocs, query, where} from "../../service/auth/firebase";
import Swal from "sweetalert2";
import {showLoading} from "../../service/swal";
import {TableContainer} from "../containers/tableContainer";
import axios from "axios";

const MasterBoard = () => {
    const navigate = useNavigate()
    const [data, setData] = useState([])

    useEffect(() => {
        showLoading()
        const fetchData = async () => {
            const q = query(collection(db, 'users'), where("type", "==", "master"))
            const res = await getDocs(q)

            const newData = res.docs.map((doc) => {
              return {
                  id: doc.id,
                  ...doc.data()
              }
            })

            setData(newData)
        }
        fetchData().then(()=> {
            Swal.close()
        })

    }, []);

    return (
        <>
            <SearchInput placeholder={"검색어를 입력해주세요..."}/>
            <Spacer height={20}/>
            <CreateButton onClick={() => navigate('create')}/>
            <Spacer height={20}/>
            <TableContainer>
                <Title>시공 기사님 리스트</Title>
                <MasterTable nodes={data}/>
            </TableContainer>
        </>
    )
}

export default MasterBoard

