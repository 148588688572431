import {TableContainer} from "../containers/tableContainer";
import {Title} from "../title";
import {useEffect, useState} from "react";
import {showLoading} from "../../service/swal";
import {orderBy, query} from "firebase/firestore";
import {collection, db, onSnapshot} from "../../service/auth/firebase";
import Swal from "sweetalert2";
import SettlementTable from "../tables/settlementTable";

const UnSettledListBoard = () => {
    const [data, setData] = useState([])

    useEffect(() => {
        showLoading()
        const q = query(collection(db, 'calculations'), orderBy("createdAt", "desc"))
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const dataArray = [];
            querySnapshot.docs.forEach((doc, i) => dataArray.push({
                id: doc.id,
                index: i,
                ...doc.data()
            }))

            setData(dataArray)
            Swal.close()
        })

        return () => {
            unsubscribe()
        }

    }, []);

    return (
        <TableContainer>
            <Title>미정산 목록</Title>
            <SettlementTable nodes={data}/>
        </TableContainer>
    )
}


export default UnSettledListBoard