import styled from "styled-components";
import moment from "moment";
import Drawer from "react-modern-drawer";
import React, {useState} from "react";
import Spacer from "../spacer";
import {Title} from "../title";
import uuid from "react-uuid";
import MasterCard from "./masterCard";

const ProgressCard = ({data}) => {
    const {
        constructionType,
        constructionStatus,
        status,
        requestDate,
        consultationType,
        customerName,
        phoneNumber,
        description,
        images,
        address,
        detailAddress,
        masters
    } = data

    const [isOpen, setIsOpen] = useState(false)
    const toggleDrawer = () => {
        setIsOpen((prevState) => !prevState)
    }

    return (
        <>
            <Card onClick={toggleDrawer}>
                <TitleContainer>
                    <ConstructionType>{constructionType}</ConstructionType>
                    <Text>{moment(requestDate.toDate(),).format('yy/MM/d')}</Text>
                </TitleContainer>
                <StatusContainer>
                    <ConsultationType status={consultationType}>{consultationType}</ConsultationType>
                    <Status $status={status}>{status}</Status>
                    <ConstructionStatus $status={constructionStatus}>{constructionStatus}</ConstructionStatus>
                </StatusContainer>
            </Card>
            <Drawer size={"90vw"} open={isOpen} direction={"right"} onClose={toggleDrawer} lockBackgroundScroll={true}
                    children={<InfoContainer>
                        <Title>품목</Title>
                        <ConstructionType>{constructionType}</ConstructionType>
                        <Spacer height={12}/>
                        <Title>상태</Title>
                        <StatusContainer>
                            <ConsultationType status={consultationType}>{consultationType}</ConsultationType>
                            <Status status={status}>{status}</Status>
                            <ConstructionStatus $status={constructionStatus}>{constructionStatus}</ConstructionStatus>
                        </StatusContainer>
                        <Spacer height={12}/>
                        <Title>요청날짜</Title>
                        <Text>{moment(requestDate.toDate(),).format('yy/MM/d')}</Text>
                        <Spacer height={12}/>
                        <Title>고객명</Title>
                        <Text>{customerName}</Text>
                        <Spacer height={12}/>
                        <Title>고객 주소</Title>
                        <Text>{address} {detailAddress}</Text>
                        <Spacer height={12}/>
                        <Title>고객 연락처</Title>
                        <Text>{phoneNumber}</Text>
                        <Spacer height={12}/>
                        <Title>설명</Title>
                        <Text>{description}</Text>
                        <Spacer height={12}/>
                        <Title>현장 사진</Title>
                        <ImageContainer>
                            {
                                images.map((image) => <Image key={uuid()} src={image}/> )
                            }
                        </ImageContainer>
                        <Spacer height={12}/>
                        <Title>담당기사</Title>
                        <MasterList>
                            {
                                masters.map((master) => <MasterCard key={uuid()} show={isOpen} uid={master}/>)
                            }
                        </MasterList>
                    </InfoContainer>}/>
        </>
    )
}

const Card = styled.div`
  width: 94%;
  padding: 3vw;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
`


const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`


const ConstructionType = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin: 4px;
`

const Text = styled.div`
  font-size: 18px;
  margin: 4px;
`

const StatusContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const ConsultationType = styled.div`
  margin: 4px;
  padding: 8px;
  font-size: 14px;
  max-width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: ${(props) => {
    switch (props.status) {
      case "긴급":
        return "red";
      case "예약":
        return "#FEFEC9";
      default:
        return "transparent";
    }
  }};
  color: ${(props) => {
    switch (props.status) {
      case "긴급":
        return "#ffffff"; // White text for better contrast
      case "예약":
        return "#FCBD02";
      default:
        return "transparent";
    }
  }};
`

const ConstructionStatus = styled.div`
  margin: 4px;
  padding: 8px;
  font-size: 14px;
  max-width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: ${(props) => {
    switch (props.status) {
      case "작업완료":
        return "#07CA08";
      case "작업예정":
        return "#FEFEC9";
      default:
        return "transparent";
    }
  }};
  color: ${(props) => {
    switch (props.status) {
      case "작업완료":
        return "#ffffff"; // White text for better contrast
      case "작업예정":
        return "#FCBD02";
      default:
        return "transparent";
    }
  }};
`

const Status = styled.div`
  margin: 4px;
  padding: 8px;
  font-size: 14px;
  max-width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: ${(props) => {
    switch (props.status) {
      case "상담완료":
        return "#07CA08";
      case "상담예정":
        return "#FEFEC9";
      default:
        return "transparent";
    }
  }};
  color: ${(props) => {
    switch (props.status) {
      case "상담완료":
        return "#ffffff"; // White text for better contrast
      case "상담예정":
        return "#FCBD02";
      default:
        return "transparent";
    }
  }};
`

const InfoContainer = styled.div`
  padding: 3vw;
  width: 94%;
  height: 94vh;
  overflow-y: scroll;
`

const ImageContainer = styled.div`
    width: 100%;
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(3, 1fr);
  padding-bottom: 20px;
`

const Image = styled.img`
    width: 100%;
  aspect-ratio: 1/1;
  border-radius: 8px;
`

const MasterList = styled.div`
    width: 100%;
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(4, 1fr);
`




export default ProgressCard