import styled from "styled-components";

const CheckIdDuplicateButton = ({onClick}) => {
    return (
        <Button onClick={onClick}>
            중복확인
        </Button>
    )
}

const Button = styled.button`
  border: none;
  background-color:#07C801;
  padding: 20px;
  font-size: 20px;
  cursor: pointer;
  border-radius: 4px;
  color: white;
`

export default CheckIdDuplicateButton