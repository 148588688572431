import styled from "styled-components";

export const Mobile = styled.div`

  display: block;
  
  @media (min-width: 801px) {
    display: none;
  }

`