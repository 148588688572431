import styled from "styled-components";
import Spacer from "../spacer";

const RadioInput = ({selected, onClick, value}) => {
    return (
        <Column>
            <Radio onClick={onClick} selected={selected}>
                <RadioInside/>
            </Radio>
            <Spacer width={12}/>
            <Label>
                {value}
            </Label>
        </Column>
    )
}

const Column = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

`

const Radio = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 40px;
  background-color: ${props => props.selected ? "#3dcc4a" : "white"};
  border: 2px solid #eee;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

const RadioInside = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 60px;
  background-color: white;
`
const Label = styled.div`
  font-size: 20px;
`

export default RadioInput