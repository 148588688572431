import styled from "styled-components";

const PenaltyMessage = ({score}) => {
    return <div>
        <Text>고객 이탈 행위에 대해서</Text>
        <Text>패널티를 부여하시겠습니까?</Text>
        <Text>({-3 + score})점이 부여됩니다.)</Text>
    </div>
}


const Text = styled.div`
    line-height: 1.7;
  font-weight: bold;
  font-size: 16px;
`

export default PenaltyMessage