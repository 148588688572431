import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from '@fullcalendar/daygrid'
import styled from "styled-components";
import koLocale from '@fullcalendar/core/locales/ko'

const MasterScheduleDrawer = () => {
    const currentDate = new Date()

    return (

            <CalendarContainer>
                <FullCalendar
                    locales={[koLocale]}
                    plugins={[dayGridPlugin]}
                    initialView={'dayGridMonth'}
                    events={[
                        {title: "작업", start: currentDate, end: new Date(2023, 10, 24), color: "#000000" },
                        {title: "작업", start: new Date(2023, 10, 27), end: new Date(2023, 10, 30), color: "#61D9FB" },
                    ]}
                />
            </CalendarContainer>

    )
}



const CalendarContainer = styled.div`
  flex: 1;
  padding: 40px;
`


export default MasterScheduleDrawer