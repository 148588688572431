import {useState} from "react";
import Drawer from 'react-modern-drawer'
import styled from "styled-components";
import {IoClose} from "react-icons/io5";

const DrawerButton = ({width, buttonChildren, drawerChildren}) => {
    const [isOpen, setIsOpen] = useState(false)
    const toggleDrawer = () => {
        setIsOpen(!isOpen)
    }



    return (
        <div>
            <ButtonContainer onClick={toggleDrawer}>
                {buttonChildren}
            </ButtonContainer>
            <Drawer
                open={isOpen}
                onClose={toggleDrawer}
                direction='right'
                size={width}
                lockBackgroundScroll={true}
            >
                <CloseButtonContainer>
                    <CloseButton onClick={toggleDrawer}>
                        <IoClose />
                    </CloseButton>
                </CloseButtonContainer>
                <ContentContainer>
                    {drawerChildren}
                </ContentContainer>
            </Drawer>
        </div>
    )
}

const ButtonContainer = styled.div`
  cursor: pointer;
`

const CloseButtonContainer = styled.div`
  width: 96%;
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`

const CloseButton = styled.button`
  border: 1px solid #eee;
  width: 48px;
  height: 48px;
  border-radius: 100px;
  background-color: white;
  font-size: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  cursor: pointer;
`

const ContentContainer = styled.div`
  width: 100%;
  height: calc(100vh - 68px);
  overflow-y: scroll;
`

export default DrawerButton

