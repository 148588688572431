export const accountBankOptions = [
    "NH농협",
    "카카오뱅크",
    "KB국민",
    "신한",
    "토스뱅크",
    "우리",
    "IBK기업",
    "하나",
    "새마을",
    "부산",
    "대구",
    "케이뱅크",
    "신협",
    "우체국",
    "SC제일",
    "경남",
    "광주",
    "수협",
    "전북",
    "저축은행",
    "제주",
    "씨티",
    "KDB산업",
    "산림조합",
    "SBI저축은행",
    "BOA",
    "중국",
    "HSBC",
    "중국공상",
    "도이치",
    "JP모건",
    "BNP파리바",
    "중국건설",
];

export const categoryOptions = [
    {
        category: "막힘해결",
        subCategory: ["변기막힘", "세면대막힘", "싱크대막힘", "하수구막힘", "맨홀/집수정청소", "변기역류", "싱크대역류", "하수구역류", "내시경검사", "배관공사"]
    },
    {
        category: "누수",
        subCategory: ["누수방지", "누수시공"]
    },
    {
        category: "방수",
        subCategory: ["옥상방수", "외벽방수", "코킹", "몰탈시공", "아스팔트"]
    },
    {
        category: "철거",
        subCategory: ["내부철거", "야외철거(간판 등)", "종합철거"]
    },
    {
        category: "해빙",
        subCategory: ["언수도", "보일러동파", "배관동파"]
    }
]


export const getCategoryBySubCategories = (subCategories) => {
    const categoryArray = [];

    for (const subCategory of subCategories) {
        for (const categoryOption of categoryOptions) {
            if (categoryOption.subCategory.includes(subCategory)) {
                if (!categoryArray.includes(categoryOption.category)) {
                    categoryArray.push(categoryOption.category);
                }
            }
        }
    }

    return categoryArray;
}