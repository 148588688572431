import ProgressCreateBoard from "../../components/boards/progressCreateBoard";
import {Container} from "../../components/containers/container";

const ProgressCreate = () => {
    return (
        <Container>
            <ProgressCreateBoard/>
        </Container>
    )
}

export default ProgressCreate