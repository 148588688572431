import React, {useCallback, useEffect, useState} from 'react'
import styled from "styled-components";
import {Title} from "../title";
import Spacer from "../spacer";
import RadioInput from "../inputs/radioInput";
import FindAddressButton from "../buttons/findAddressButton";
import DateInput from "../inputs/dateInput";
import {
    addDoc,
    collection,
    db,
    getDownloadURL,
    ref,
    serverTimestamp,
    storage,
    uploadBytes,
    auth, query, where, setDoc
} from "../../service/auth/firebase";
import {useNavigate} from "react-router-dom";
import {useDropzone} from "react-dropzone";
import uuid from "react-uuid";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Message from "../messages/message";
import {showErrorMessage, showLoading, showSuccessMessage} from "../../service/swal";
import {BoardContainer} from "../containers/boardContainer";

const ProgressCreateBoard = () => {
    const navigate = useNavigate()
    const [checkedAssignment, setCheckedAssignment] = useState("선착순 배정")
    const [constructionType, setConstructionType] = useState("변기막힘")
    const [consultationType, setConsultationType] = useState("긴급")
    const [customerName, setCustomerName] = useState("")
    const [issueDescription, setIssueDescription] = useState("")
    const [files, setFiles] = useState([])
    const [paths, setPaths] = useState([])
    const [address, setAddress] = useState('');
    const [requestDate, setRequestDate] = useState(Date.now())
    const [startDate, setStartDate] = useState(Date.now())
    const [endDate, setEndDate] = useState(Date.now())
    const [phoneNumber1, setPhoneNumber1] = useState("")
    const [phoneNumber2, setPhoneNumber2] = useState("")
    const [phoneNumber3, setPhoneNumber3] = useState("")
    const [detailAddress, setDetailAddress] = useState("")
    const [modalIsOpen, setIsOpen] = useState(false)
    const [masters, setMasters] = useState([])
    const [masterOptions, setMasterOptions] = useState([])


    const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
        setPaths(acceptedFiles.map(file => URL.createObjectURL(file)))
        setFiles(acceptedFiles)
    }, [setPaths, setFiles])

    const onDropRejected = useCallback(() => {
        alert('파일 크기가 제한을 초과했습니다.');
    }, []);

    const {getRootProps, getInputProps} = useDropzone({
        onDrop,
        onDropRejected,
        maxSize: 100 * 1024 * 1024  // 최대 용량 옵션 값 추가
    })

    const openModal = () => {
        setIsOpen(true)
    }

    const closeModal = () => {
        setIsOpen(false)
    }
    const onCompleteFindAddress = (data) => {
        setAddress(data.address)
        setIsOpen(false)
    }

    const onClickSaveButton = async () => {
        withReactContent(Swal).fire({
            customClass: {
                actions: "actions",
                loader: <span className="loader"></span>,
                confirmButton: "confirm__button",
                cancelButton: "cancel__button",
            },
            width: 320,
            height: 100,
            buttonsStyling: true,
            html: <Message>생성하시겠습니까?</Message>,
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "네",
            cancelButtonText: "아니오",
            showCloseButton: !Swal.isLoading(),
            allowOutsideClick: !Swal.isLoading(),
            preConfirm: async (login) => {
                showLoading()
                const date = new Date()
                let imageUrls = []
                try {
                    for (const file of files) {
                        if (file) {
                            const metadata = {
                                contentType: 'image'
                            }
                            const storageRef = ref(storage, `${constructionType}/${date.toISOString()}/${file.name}`)
                            const snapshot = await uploadBytes(storageRef, file, metadata)
                            const url = await getDownloadURL(snapshot.ref)
                            imageUrls.push(url)
                        }
                    }
                    const requestCollectionRef = collection(db, 'requests')
                    const requestResultDoc = await addDoc(requestCollectionRef, {
                        address: address,
                        detailAddress: detailAddress,
                        customerName: customerName,
                        phoneNumber: `${phoneNumber1}-${phoneNumber2}-${phoneNumber3}`,
                        consultationType: consultationType,
                        constructionType: constructionType,
                        images: imageUrls,
                        description: issueDescription,
                        createdAt: date,
                        updatedAt: serverTimestamp(),
                        requestUser: auth.currentUser.uid,
                        status: masters.length === 0 ? "기사 미배정" : "기사 배정완료"
                    })
                    const progressCollectionRef = collection(db, 'progresses').doc(requestResultDoc.id)
                    await setDoc(progressCollectionRef, {
                        masters: masters,
                        files: [],
                        status: "상담예정",
                        requestDate: date,
                        cost: 0,
                        request: requestResultDoc.id,
                        address: address,
                        images: imageUrls,
                        description: issueDescription,
                        constructionType: constructionType,
                        detailAddress: detailAddress,
                        customerName: customerName,
                        phoneNumber: `${phoneNumber1}-${phoneNumber2}-${phoneNumber3}`,
                        assignmentType: checkedAssignment,
                        lastRequestUser: auth.currentUser.uid,
                        fee: 0,
                        createdAt: serverTimestamp(),
                        updatedAt: serverTimestamp()
                    })
                } catch (error) {
                    showErrorMessage("저장실패")
                }
            }
        }).then((result) => {
            if (result.isConfirmed) {
                showSuccessMessage("저장완료")
                navigate(-1)
            }
        })

    };

    useEffect(() => {
        const q = query(collection(db, 'users'), where('status', '==', "activated"))
    }, []);


    return (
        <BoardContainer>
            <Title>신규 작업 생성</Title>
            <Spacer height={40}/>
            <Label>관리번호</Label>
            <Spacer width={20}/>
            <Input disabled placeholder={"자동으로 입력됩니다."}/>
            <Spacer height={20}/>
            <Label>문의날짜<Required>*</Required></Label>
            <Spacer width={20}/>
            <DateInput onChange={(date) => setRequestDate(date)} selected={requestDate}/>
            <Spacer height={20}/>
            <Label>고객 연락처<Required>*</Required></Label>
            <Spacer width={20}/>
            <Select value={consultationType} onChange={(e) => setConsultationType(e.target.value)}>
                <option value="긴급">긴급</option>
                <option value="예약">예약</option>
            </Select>
            <Spacer height={20}/>
            <Label>품목</Label>
            <Spacer width={20}/>
            <Select value={constructionType} onChange={(e) => setConstructionType(e.target.value)}>
                <option value="변기막힘">변기 막힘</option>
                <option value="세면대막힘">세면대 막힘</option>
                <option value="싱크대막힘">싱크대 막힘</option>
                <option value="하수구막힘">하수구 막힘</option>
                <option value="맨홀/집수정청소">맨홀/집수정 청소</option>
                <option value="변기역류">변기 역류</option>
                <option value="싱크대역류">싱크대 역류</option>
                <option value="하수구역류">하수구 역류</option>
                <option value="배관 내시경검사">배관 내시경 검사</option>
                <option value="배관공사">배관 공사</option>
            </Select>
            <Spacer height={20}/>
            <Label>상세 설명</Label>
            <Spacer height={20}/>
            <TextArea onChange={(e) => setIssueDescription(e.target.value)} value={issueDescription}/>
            <Spacer height={20}/>
            <Label>현장사진</Label>
            <DropZone {...getRootProps()}>
                <input {...getInputProps()} />
                {paths.length === 0 && <div>클릭 또는 파일 드래그</div>}
                {paths.length !== 0 && <ImageGrid>
                    {
                        paths.map((path) => <Image key={uuid()} src={path}/>)
                    }
                </ImageGrid>}
            </DropZone>
            <Spacer height={20}/>
            <Label>고객성함(업체)<Required>*</Required></Label>
            <Spacer width={20}/>
            <Input value={customerName} onChange={(e) => setCustomerName(e.target.value)} required/>
            <Label>고객 연락처<Required>*</Required></Label>
            <Spacer width={20}/>
            <Row>
                <PhoneNumberInput maxLength={3} onChange={(e) => setPhoneNumber1(e.target.value)}
                                  value={phoneNumber1}/>
                <PhoneNumberDash>-</PhoneNumberDash>
                <PhoneNumberInput maxLength={4} onChange={(e) => setPhoneNumber2(e.target.value)}
                                  value={phoneNumber2}/>
                <PhoneNumberDash>-</PhoneNumberDash>
                <PhoneNumberInput maxLength={4} onChange={(e) => setPhoneNumber3(e.target.value)}
                                  value={phoneNumber3}/>
            </Row>
            <Spacer height={20}/>
            <Label>주소<Required>*</Required></Label>
            <Spacer width={20}/>
            <Row>
                <Input disabled value={address}/>
                <Spacer width={20}/>
                <FindAddressButton modalIsOpen={modalIsOpen} onClick={openModal} closeModal={closeModal}
                                   onComplete={onCompleteFindAddress}/>
            </Row>
            <Spacer height={20}/>
            <Spacer width={20}/>
            <Input onChange={(e) => setDetailAddress(e.target.value)} value={detailAddress} required/>

            <Spacer height={20}/>
            <Label>담당기사<Required>*</Required></Label>
            <Spacer height={20}/>
            <Row>
                <RadioInput selected={checkedAssignment === "선착순 배정"} onClick={() => setCheckedAssignment("선착순 배정")}
                            value={"선착순 배정"}/>
                <Spacer width={80}/>
                <RadioInput selected={checkedAssignment === "지정 배정"} onClick={() => setCheckedAssignment("지정 배정")}
                            value={"지정 배정"}/>
            </Row>
            {
                checkedAssignment === "지정 배정" && <>
                    <Spacer height={20}/>
                    <Select>
                        <option value="담당기사선택">담당기사 선택</option>
                    </Select>
                </>
            }

            <Spacer height={20}/>
            <Label>시작날짜</Label>
            <Spacer width={20}/>
            <DateInput onChange={(date) => setStartDate(date)} selected={startDate}/>
            <Label>종료날짜</Label>
            <Spacer width={20}/>
            <DateInput onChange={(date) => setEndDate(date)} selected={endDate}/>
            <Label>작업상태</Label>
            <Spacer width={20}/>
            <Input disabled placeholder={"선택 불가"}/>
            <Spacer height={20}/>
            <Label>비용(VAT별도)</Label>
            <Spacer width={20}/>
            <Input disabled placeholder={"입력 불가"}/>
            <Spacer height={40}/>
            <SaveButton onClick={onClickSaveButton}>생성하기</SaveButton>
        </BoardContainer>
    )
}

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Label = styled.label`
  font-size: 24px;
  width: 240px;
  padding-top: 20px;
  padding-bottom: 20px;
`

const Input = styled.input`
  width: 600px;
  padding: 20px;
  font-size: 24px;
  border: none;
  border-radius: 4px;
  box-shadow: rgba(50, 50, 93, 0.25) 0 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;


  &:focus {
    outline: 1px solid #07C801;
  }
`

const TextArea = styled.textarea`
  resize: none;
  width: 800px;
  height: 500px;
  padding: 20px;
  font-size: 24px;
  border: none;
  border-radius: 4px;
  box-shadow: rgba(50, 50, 93, 0.25) 0 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;


  &:focus {
    outline: 1px solid #07C801;
  }
`

const Required = styled.span`
  font-size: 16px;
  color: red;
  margin-left: 8px;
  margin-right: 8px;
`

const PhoneNumberInput = styled.input`
  padding: 20px;
  font-size: 24px;
  width: 140px;
  border: none;
  border-radius: 4px;
  box-shadow: rgba(50, 50, 93, 0.25) 0 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;


  &:focus {
    outline: 1px solid #07C801;
  }
`

const PhoneNumberDash = styled.div`
  width: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Select = styled.select`
  width: 400px;
  padding: 20px;
  font-size: 24px;
  border: none;
  border-radius: 4px;
  box-shadow: rgba(50, 50, 93, 0.25) 0 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

  -webkit-appearance: none; /* Hide arrow in Chrome/Safari */
  -moz-appearance: none; /* Hide arrow in Firefox */
  appearance: none; /* Hide arrow in other browsers */

  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='6' viewBox='0 0 12 6'><polygon points='0,0 12,0 6,6'/></svg>");
  background-repeat: no-repeat;
  background-position: right 15px center; /* Adjust the position as needed */
  background-size: 8px; /* Adjust the size as needed */

  &:focus {
    outline: 1px solid #07C801;
  }

  cursor: pointer;
`

const DropZone = styled.div`
  cursor: pointer;
  background-color: #eee;
  border-radius: 4px;
  min-width: 400px;
  min-height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ImageGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 300px);
  grid-gap: 20px;
  padding: 20px;
`

const Image = styled.img`
  width: 250px;
  padding: 25px;
  height: 250px;
  object-fit: contain;
  background-color: white;
  border-radius: 8px;

  box-shadow: rgba(50, 50, 93, 0.25) 0 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
`

const SaveButton = styled.button`
  cursor: pointer;
  border-radius: 4px;
  border: none;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: white;
  font-weight: bold;
  background-color: #07C801;
  height: 62px;
  box-shadow: rgba(50, 50, 93, 0.25) 0 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
`

export default ProgressCreateBoard