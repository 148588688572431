import MasterBoard from "../../components/boards/masterBoard";
import {Container} from "../../components/containers/container";

const Master = () => {
    return (
        <Container>
            <MasterBoard/>
        </Container>
    )
}

export default Master