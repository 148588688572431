import React, {Fragment, useEffect, useState} from 'react'
import styled from "styled-components";
import {Title} from "../title";
import Spacer from "../spacer";
import FindAddressButton from "../buttons/findAddressButton";
import DateInput from "../inputs/dateInput";
import Dropzone from "react-dropzone";
import uuid from "react-uuid";
import CreateButton from "../buttons/createButton";
import DeleteButton from "../buttons/deleteButton";
import CategoryButton from "../buttons/categoryButton";
import CategoryTag from "../tags/categoryTag";
import {showErrorMessage, showLoading, showSuccessMessage} from "../../service/swal";
import CheckIdDuplicateButton from "../buttons/checkIdDuplicateButton";
import {
    collection,
    db,
    getDocs,
    getDownloadURL,
    query,
    ref,
    storage,
    uploadBytes,
    where
} from "../../service/auth/firebase";
import {accountBankOptions, categoryOptions} from "../../service/option";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import MasterCreateMessage from "../messages/masterCreateMessage";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import {BoardContainer} from "../containers/boardContainer";


const MasterCreateBoard = () => {
    const navigate = useNavigate()
    const [name, setName] = useState('');
    const [id, setId] = useState('');
    const [checkDuplicate, setCheckDuplicate] = useState(false)
    const [branchName, setBranchName] = useState('');
    const [accountBank, setAccountBank] = useState('NH농협');
    const [accountNumber, setAccountNumber] = useState('');
    const [contracts, setContracts] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            files: []
        }
    ])
    const [branchAddress, setBranchAddress] = useState('');
    const [phoneNumber1, setPhoneNumber1] = useState("")
    const [phoneNumber2, setPhoneNumber2] = useState("")
    const [phoneNumber3, setPhoneNumber3] = useState("")
    const [branchDetailAddress, setBranchDetailAddress] = useState("")
    const [modalIsOpen, setIsOpen] = useState(false)
    const [selectedCategory, setSelectedCategory] = useState(categoryOptions[0])
    const [selectedSubCategories, setSelectedSubCategories] = useState([])


    const onClickCheckDuplicateButton = async () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const checkEmailString = emailRegex.test(id);
        if (!checkEmailString) {
            showErrorMessage("이메일 형식으로 입력해주세요")
        } else {
            const collectionRef = collection(db, 'users')
            const q = query(collectionRef, where("email", "==", id))
            const querySnapshot = await getDocs(q)
            if (querySnapshot.docs.length !== 0) {
                showErrorMessage("이미 사용중인 아이디입니다.")
                setCheckDuplicate(false)
            } else {
                showSuccessMessage("사용하실 수 있는 아이디입니다.")
                setCheckDuplicate(true)
            }
        }
    }

    const openModal = () => {
        setIsOpen(true)
    }

    const closeModal = () => {
        setIsOpen(false)
    }

    const onClickCreateContractButton = () => {
        const updatedContracts = [...contracts]
        updatedContracts.push({
            startDate: Date.now(),
            endDate: Date.now(),
            file: []
        })
        setContracts(updatedContracts)
    }

    const onClickDeleteContractButton = (i) => {
        if (i > 0 && i < contracts.length) {
            const updatedContracts = [...contracts];
            updatedContracts.splice(i, 1); // Remove the contract at index i
            setContracts(updatedContracts);
        }
    }

    const onCompleteFindAddress = (data) => {
        setBranchAddress(data.address)
        setIsOpen(false)
    }

    const onClickSubCategoryButton = (subCategory) => {
        // Check if the subCategory is already selected
        if (selectedSubCategories.includes(subCategory)) {
            // If selected, remove it
            const updatedSubCategories = selectedSubCategories.filter(category => category !== subCategory);
            setSelectedSubCategories(updatedSubCategories);
        } else {
            // If not selected, add it
            setSelectedSubCategories([...selectedSubCategories, subCategory]);
        }
    }

    const onClickSubCategoryButtonCloseIcon = (subCategoryToRemove) => {
        const updatedSubCategories = selectedSubCategories.filter(subCategory => subCategory !== subCategoryToRemove);
        setSelectedSubCategories(updatedSubCategories);
    }

    const onChangeStartDateInput = (date, index) => {
        let updatedContracts = [...contracts];
        updatedContracts[index] = {
            ...updatedContracts[index],
            startDate: date,
        };
        setContracts(updatedContracts);
    }

    const onChangeEndDateInput = (date, index) => {
        let updatedContracts = [...contracts];
        updatedContracts[index] = {
            ...updatedContracts[index],
            endDate: date,
        };
        setContracts(updatedContracts);
    }

    const onClickCreateButton = async () => {
        const data = {
            name,
            id,
            branchName,
            branchAddress,
            branchDetailAddress,
            phoneNumber: `${phoneNumber1}-${phoneNumber2}-${phoneNumber3}`,
            contracts,
            accountBank,
            accountNumber,
            selectedSubCategories,
        }
        if (name.trim() === "") {
            showErrorMessage("성명을 입력해주세요")
        } else if (!checkDuplicate) {
            showErrorMessage("아이디 중복확인을 해주세요")
        } else if (phoneNumber2.trim().length === 0 || phoneNumber1.trim().length === 0 || phoneNumber3.trim().length === 0) {
            showErrorMessage("전화번호를 입력하세요")
        } else if (selectedSubCategories.length === 0) {
            showErrorMessage("품목을 1개 이상 선택해주세요")
        } else {
            withReactContent(Swal).fire({
                customClass: {
                    actions: "actions",
                    confirmButton: "confirm__button",
                    cancelButton: "cancel__button"
                },
                html: <MasterCreateMessage data={data}/>,
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: "네, 추가합니다",
                cancelButtonText: "다시 입력하기",

            }).then(async (result) => {

                if (result.isConfirmed) {
                    showLoading()
                    let uploadContracts = [];
                    for (let i = 0; i < contracts.length; i++) {
                        const files = contracts[i].files;
                        const storageRef = ref(storage, `계약서/${id}/${contracts[i].startDate.toISOString()}~${contracts[i].endDate.toISOString()}/`);
                        if (files.length>0) {
                            const fileRef = ref(storageRef, files[0].name);
                            await uploadBytes(fileRef, files[0]);
                            const downloadURL = await getDownloadURL(fileRef);
                            uploadContracts.push({
                                startDate: contracts[i].startDate.toISOString(),
                                endDate: contracts[i].endDate.toISOString(),
                                files: [downloadURL]
                            })
                        }
                    }

                    const res = await axios.post('https://addmasteraccount-3px66uhioq-du.a.run.app', {
                        email: id,
                        accountStatus: "active",
                        displayName: name,
                        phoneNumber: `${phoneNumber1}-${phoneNumber2}-${phoneNumber3}`,
                        photoUrl: "",
                        type: "master",
                        branchName,
                        branchAddress,
                        branchDetailAddress,
                        contracts: uploadContracts,
                        accountBank,
                        accountNumber,
                        tags: selectedSubCategories
                    })
                    if (res.data.result === "account Created") {
                        showSuccessMessage("성공적으로 생성되었습니다.")
                        navigate('/dashboard/master')
                    }
                }
            }).catch(error => {
                showErrorMessage("유저 생성 실패")
            })
        }
    }

    useEffect(() => {
        setCheckDuplicate(false)
    }, [id]);

    useEffect(() => {

    }, [contracts]);

    return (
            <BoardContainer>
                <Title>신규 기사 생성</Title>
                <Spacer height={40}/>
                <Label>성명<Required>*</Required></Label>
                <Spacer width={20}/>
                <Input type={"text"} onChange={(e) => setName(e.target.value)} value={name}/>
                <Spacer height={20}/>
                <Label>아이디<Required>*</Required></Label>
                <Spacer width={20}/>
                <Row>
                    <Input type={"email"} onChange={(e) => setId(e.target.value)} value={id}/>
                    <Spacer width={20}/>
                    <CheckIdDuplicateButton onClick={onClickCheckDuplicateButton}/>
                </Row>
                <Message checkDuplicate={checkDuplicate}>{checkDuplicate ? "중복확인되었습니다." : "중복확인을 해야합니다."}</Message>
                <Spacer height={20}/>
                <Label>전화번호<Required>*</Required></Label>
                <Spacer width={20}/>
                <Row>
                    <PhoneNumberInput maxLength={3} onChange={(e) => setPhoneNumber1(e.target.value)}
                                      value={phoneNumber1}/>
                    <PhoneNumberDash>-</PhoneNumberDash>
                    <PhoneNumberInput maxLength={4} onChange={(e) => setPhoneNumber2(e.target.value)}
                                      value={phoneNumber2}/>
                    <PhoneNumberDash>-</PhoneNumberDash>
                    <PhoneNumberInput maxLength={4} onChange={(e) => setPhoneNumber3(e.target.value)}
                                      value={phoneNumber3}/>
                </Row>
                <Spacer height={20}/>
                <Label>지점명</Label>
                <Spacer width={20}/>
                <Input type={"text"} onChange={(e) => setBranchName(e.target.value)} value={branchName}/>
                <Spacer height={20}/>
                <Label>지점주소<Required>*</Required></Label>
                <Spacer width={20}/>
                <Row>
                    <Input disabled value={branchAddress}/>
                    <Spacer width={20}/>
                    <FindAddressButton modalIsOpen={modalIsOpen} onClick={openModal} closeModal={closeModal}
                                       onComplete={onCompleteFindAddress}/>
                </Row>
                <Spacer height={20}/>
                <Input onChange={(e) => setBranchDetailAddress(e.target.value)} value={branchDetailAddress}
                       required/>
                <Spacer height={20}/>
                <Label>계약</Label>
                <CreateButton onClick={onClickCreateContractButton}/>
                <Spacer height={20}/>
                <ContractContainer>
                    {contracts.map((contract, index) => <Row key={uuid()} style={{marginTop: 8, marginBottom: 8}}>
                        <DateInput selected={contract.startDate}
                                   onChange={(date) => onChangeStartDateInput(date, index)}/>
                        <PhoneNumberDash> ~ </PhoneNumberDash>
                        <DateInput selected={contract.endDate}
                                   onChange={(date) => onChangeEndDateInput(date, index)}/>
                        <Spacer width={40}/>
                        <Dropzone maxFiles={1} onDrop={(acceptedFiles, fileRejections) => {
                            if (fileRejections.length > 0) {
                                showErrorMessage("1개의 파일만 첨부해주세요")
                            }else {
                                let updatedContracts = [...contracts]
                                updatedContracts[index].files = [...acceptedFiles]
                                setContracts(updatedContracts)
                            }


                        }}>
                            {({getRootProps, getInputProps}) => (<FileInput {...getRootProps()}>
                                <input {...getInputProps()} />
                                {contract.files && contract.files.length === 0 && <div>계약서 첨부파일</div>}
                                {contract.files && contract.files.length !== 0 && contract.files.map((file) =>
                                    <FileTag
                                        key={uuid()}>{file.name}</FileTag>)}
                            </FileInput>)}
                        </Dropzone>
                        <Spacer width={20}/>
                        {index !== 0 && <DeleteButton onClick={() => onClickDeleteContractButton(index)}/>}
                    </Row>)}
                </ContractContainer>
                <Spacer height={20}/>
                <Label>계좌번호<Required>*</Required></Label>
                <Row>
                    <Select onChange={(e) => setAccountBank(e.target.value)} value={accountBank}>
                        {accountBankOptions.map((option) => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </Select>
                    <Spacer width={20}/>
                    <Input onChange={(e) => setAccountNumber(e.target.value)} value={accountNumber}/>
                </Row>
                <Label>업종</Label>
                <Row>
                    {
                        categoryOptions.map((option, index) =>
                            <>
                                <CategoryButton selected={selectedCategory.category === option.category}
                                                onClick={() => setSelectedCategory(option)}>
                                    {option.category}
                                </CategoryButton>
                                <Spacer width={12}/>
                            </>)
                    }
                </Row>
                <Spacer height={20}/>
                <CategoryListContainer>
                    {
                        selectedCategory.subCategory.map((subCategory) => <CategoryButton
                            onClick={() => onClickSubCategoryButton(subCategory)}
                            selected={selectedSubCategories.includes(subCategory)}>
                            {subCategory}
                        </CategoryButton>)
                    }
                </CategoryListContainer>
                <Spacer height={20}/>
                <Label>선택된 품목</Label>
                <Spacer height={20}/>
                <SelectedCategoryContainer>
                    {
                        selectedSubCategories.map((selectedSubCategory) => <CategoryTag key={uuid()}
                                                                                        onClickCloseIcon={() => onClickSubCategoryButtonCloseIcon(selectedSubCategory)}>{selectedSubCategory}</CategoryTag>)
                    }
                </SelectedCategoryContainer>

                <Spacer height={40}/>
                <SaveButton onClick={onClickCreateButton}>생성하기</SaveButton>
            </BoardContainer>
    )
}




const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Label = styled.label`
  font-size: 24px;
  width: 240px;
  padding-top: 20px;
  padding-bottom: 20px;
`

const Input = styled.input`
  width: 600px;
  padding: 20px;
  font-size: 24px;
  border: none;
  border-radius: 4px;
  box-shadow: rgba(50, 50, 93, 0.25) 0 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;


  &:focus {
    outline: 1px solid #07C801;
  }
`
const Required = styled.span`
  font-size: 16px;
  color: red;
  margin-left: 8px;
  margin-right: 8px;
`

const PhoneNumberInput = styled.input`
  padding: 20px;
  font-size: 24px;
  width: 140px;
  border: none;
  border-radius: 4px;
  box-shadow: rgba(50, 50, 93, 0.25) 0 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;


  &:focus {
    outline: 1px solid #07C801;
  }
`

const PhoneNumberDash = styled.div`
  width: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Select = styled.select`
  width: 200px;
  padding: 20px;
  font-size: 24px;
  border: none;
  border-radius: 4px;
  box-shadow: rgba(50, 50, 93, 0.25) 0 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

  -webkit-appearance: none; /* Hide arrow in Chrome/Safari */
  -moz-appearance: none; /* Hide arrow in Firefox */
  appearance: none; /* Hide arrow in other browsers */

  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='6' viewBox='0 0 12 6'><polygon points='0,0 12,0 6,6'/></svg>");
  background-repeat: no-repeat;
  background-position: right 15px center; /* Adjust the position as needed */
  background-size: 8px; /* Adjust the size as needed */

  &:focus {
    outline: 1px solid #07C801;
  }

  cursor: pointer;
`

const SaveButton = styled.button`
  cursor: pointer;
  border-radius: 4px;
  border: none;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: white;
  font-weight: bold;
  background-color: #07C801;
  height: 62px;
  box-shadow: rgba(50, 50, 93, 0.25) 0 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
`


const ContractContainer = styled.div`
  padding: 20px;
  background-color: #eee;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
`

const FileInput = styled.div`
  background-color: white;
  padding: 8px;
  border-radius: 4px;
  min-width: 400px;
  max-width: 400px;
  min-height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

const CategoryListContainer = styled.div`
  padding: 20px;
  display: grid;
  width: 800px;
  border-radius: 4px;
  grid-gap: 20px;
  grid-template-columns: repeat(4, 1fr);
  box-shadow: rgba(50, 50, 93, 0.25) 0 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
`

const Message = styled.div`
  margin-top: 12px;
  font-size: 16px;
  color: ${props => props.checkDuplicate ? "#07C801" : "red"};
  margin-bottom: 12px;
`

const SelectedCategoryContainer = styled.div`
  padding: 20px;
  min-width: 720px;
  background-color: #eee;
  border-radius: 4px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
`

const FileTag = styled.div`
  padding: 12px;
  border-radius: 4px;
  color: white;
  background-color: #07C801;
  margin-left: 8px;
  text-overflow: ellipsis;
`

export default MasterCreateBoard