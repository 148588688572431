import MasterUpdateBoard from "../../components/boards/masterUpdateBoard";
import {Container} from "../../components/containers/container";

const MasterUpdate = () => {
    return (
        <Container>
            <MasterUpdateBoard/>
        </Container>

    )
}

export default MasterUpdate