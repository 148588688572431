import React, {useState} from "react";
import styled from "styled-components";
import Spacer from "../components/spacer";
import {auth, signInWithEmailAndPassword} from "../service/auth/firebase";
import {Navigate} from "react-router-dom";
import {showErrorMessage} from "../service/swal";
import {useAuthStore} from "../store/store";

const Login = () => {
    const {user} = useAuthStore()
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    const handleLogin = async (e) => {
        e.preventDefault()
        try {
            await signInWithEmailAndPassword(auth, email, password)
            return <Navigate to={'/dashboard'}/>
        } catch (error) {
           showErrorMessage("유저정보가 일치하지 않습니다.")
        }
    }

    if(user){
       return <Navigate to={'/dashboard'}/>
    }

    return (
        <Background>
            <LoginForm onSubmit={handleLogin}>
                <ImageContainer>
                    <img src={"/images/logo.png"} alt={"로고"}/>
                </ImageContainer>

                <label htmlFor="username">이메일</label>
                <input
                    type="email"
                    id="username"
                    name="username"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <Spacer height={20}/>
                <label htmlFor="password">비밀번호</label>
                <input
                    type="password"
                    id="password"
                    name="password"
                    required
                    onChange={(e) => setPassword(e.target.value)}
                />
                <Spacer height={20}/>
                <button type="submit">로그인</button>
            </LoginForm>
        </Background>
    );
};

const Background = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0; /* Set your desired background color */
`;

const ImageContainer = styled.div`
  width: 100%;
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const LoginForm = styled.form`
  width: 400px;
  background-color: #ffffff; /* Set your desired background color */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  label {
    display: block;
    margin-bottom: 8px;
  }

  input {
    width: 100%;
    padding: 8px;
    margin-bottom: 16px;
    box-sizing: border-box;
  }

  button {
    width: 100%;
    background-color: #4caf50; /* Set your desired button color */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
`;

export default Login;
