import SearchInput from "../inputs/searchInput";
import Spacer from "../spacer";
import CreateButton from "../buttons/createButton";
import ProgressCard from "../cards/progressCard";
import React, {Fragment, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {orderBy, query} from "firebase/firestore";
import {collection, db, onSnapshot} from "../../service/auth/firebase";
import Swal from "sweetalert2";
import {showLoading} from "../../service/swal";
import {Desktop} from "../responsive/desktop";
import {Mobile} from "../responsive/mobile";
import {CardContainer} from "../containers/cardContainer";
import ProgressTable from "../tables/progressTable";
import {TableContainer} from "../containers/tableContainer";

const ProgressBoard = () => {
    const navigate = useNavigate()
    const [data, setData] = useState([])


    useEffect(() => {
        showLoading()
        const q = query(collection(db, 'progresses'), orderBy("createdAt", "desc"))
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const dataArray = [];
            querySnapshot.docs.forEach((doc) => dataArray.push({
                id: doc.id,
                ...doc.data()
            }))

            setData(dataArray)
            Swal.close()
        })

        return () => {
            unsubscribe()
        }

    }, []);


    return (
        <>
            <SearchInput/>
            <Spacer height={20}/>
            <CreateButton onClick={() => navigate('create')}/>
            <Spacer height={20}/>
            <Desktop>
                <TableContainer>
                    <ProgressTable nodes={data}/>
                </TableContainer>
            </Desktop>
            <Mobile>
                <CardContainer>
                    {
                        data.map((item) => {
                            return <Fragment key={item.id}>
                                <ProgressCard data={item}/>
                                <Spacer height={20}/>

                            </Fragment>
                        })
                    }
                </CardContainer>


            </Mobile>
            <Spacer height={20}/>

        </>
    )
}

export default ProgressBoard