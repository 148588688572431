import styled from "styled-components";
import {IoClose} from "react-icons/io5";

const CategoryTag = ({children, onClickCloseIcon}) => {
    return (
        <Tag>
            <div>{children}</div>
            <IoClose onClick={onClickCloseIcon}/>
        </Tag>
    )
}

const Tag = styled.button`
  border: none;
  box-shadow: rgba(50, 50, 93, 0.25) 0 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border-radius: 4px;
  background-color: ${props => props.selected?"#07C801":"white"};
  color: ${props => props.selected?"white":"black"};
  font-size: 20px;
  padding: 20px;
  min-width: 120px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export default CategoryTag