import moment from "moment/moment";
import {useTheme} from "@table-library/react-table-library/theme";
import {getTheme} from "@table-library/react-table-library/baseline";
import {CompactTable} from "@table-library/react-table-library/compact";
import styled from "styled-components";
import DrawerButton from "../buttons/drawerButton";
import {useEffect, useState} from "react";
import {collection, db, onSnapshot, where} from "../../service/auth/firebase";
import Spacer from "../spacer";
import {Title} from "../title";
import uuid from "react-uuid";
import notFound from "../../json/not-found.json";
import Lottie from "lottie-react";
import {orderBy, query} from "firebase/firestore";


const RequestTable = () => {
    const [data, setData] = useState([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        const q = query(collection(db, 'requests'), where('status', '==', "기사 미배정"), orderBy("createdAt", "desc"))
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const dataArray = [];
            querySnapshot.docs.forEach((doc) => dataArray.push({
                id: doc.id,
                ...doc.data()
            }))
            setData(dataArray)
        })

        return () => {
            unsubscribe()
        }

    }, []);

    const theme = useTheme([getTheme(),
        {
            HeaderRow: `
             .th {
                text-align: center
             }
            `,
            Table: `
             --data-table-library_grid-template-columns: repeat(7, 1fr);
             text-align: center;
            `,
            BaseCell: `
              padding: 12px 0px;
            `
        }
    ])

    const columns = [
        {
            label: '상담유형', renderCell: (item) => <Container key={uuid()}>
                <ConsultationType status={item.consultationType}>{item.consultationType}</ConsultationType>
            </Container>
        },
        {label: '문의날짜', renderCell: (item) => moment(item.createdAt.toDate()).format('YY/MM/DD')},
        {label: '고객성함(업체)', renderCell: (item) => item.customerName},
        {label: '주소', renderCell: (item) => item.address},
        {label: '품목', renderCell: (item) => item.constructionType,},
        {label: '연락처', renderCell: (item) => item.phoneNumber},
        {
            label: '',
            renderCell: (item) => <Container>
                <DrawerButton
                    width={500}
                    buttonChildren={<MasterAlignButton>기사배정</MasterAlignButton>}
                    drawerChildren={
                        <DetailBackground>
                            <Title>요청내용</Title>
                            <DetailContainer>
                                <RequestTitle>고객요청번호</RequestTitle>
                                <RequestDescription>{item.id}</RequestDescription>
                                <Spacer height={12}/>
                                <RequestTitle>상담 유형</RequestTitle>
                                <RequestDescription>
                                    <ConsultationType
                                        status={item.consultationType}>{item.consultationType}</ConsultationType>
                                </RequestDescription>
                                <Spacer height={12}/>
                                <RequestTitle>문의날짜</RequestTitle>
                                <RequestDescription>{moment(item.createdAt.toDate()).format('YY/MM/DD')}</RequestDescription>
                                <Spacer height={12}/>
                                <RequestTitle>고객성함(업체)</RequestTitle>
                                <RequestDescription>{item.customerName}</RequestDescription>
                                <Spacer height={12}/>
                                <RequestTitle>주소</RequestTitle>
                                <RequestDescription>{item.address}</RequestDescription>
                                <Spacer height={12}/>
                                <RequestTitle>상세주소</RequestTitle>
                                <RequestDescription>{item.detailAddress}</RequestDescription>
                                <Spacer height={12}/>
                                <RequestTitle>상세설명</RequestTitle>
                                <RequestDescription>
                                    {item.description}
                                </RequestDescription>
                                <Spacer height={12}/>
                                <RequestTitle>이미지</RequestTitle>
                                <RequestImageGrid>
                                    {item.images.map((imageUrl) =>
                                        <img
                                            key={uuid()}
                                            width={300}
                                            src={imageUrl}
                                            loading={"lazy"}
                                            alt={"요청 이미지"}/>
                                    )
                                    }
                                </RequestImageGrid>
                            </DetailContainer>
                        </DetailBackground>
                    }
                />
            </Container>
        },
    ];
    return <>
        {data.length === 0 ? <EmptyContainer>
            <Lottie animationData={notFound} style={{width: 100}}/>
            <Spacer height={12}/>
            <EmptyMessage>등록된 홈페이지 문의가 없습니다.</EmptyMessage>
        </EmptyContainer> : <div><CompactTable
            columns={columns}
            data={{nodes: data}}
            theme={theme}
            layout={{custom: true}}
        />
        </div>}
    </>

}

const ConsultationType = styled.div`
  padding: 4px 8px;
  max-width: 60px;
  color: white;
  font-size: 1vw;
  border-radius: 4px;
  background-color: ${props => {
    switch (props.status) {
      case "긴급":
        return "red";
      case "예약":
        return "yellow"
      default:
        return "white"
    }
  }};
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const MasterAlignButton = styled.div`
  padding: 4px 8px;
  border-radius: 4px;
  text-align: center;
  background-color: black;
  font-size: 1vw;
  color: white;
  cursor: pointer;
`

const DetailBackground = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const DetailContainer = styled.div`
  width: 80%;
  height: 500px;
  border-radius: 12px;
  border: 1px solid #3dcc4a;
  padding: 20px;
  margin: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: scroll;
`

const RequestTitle = styled.div`
  margin: 8px;
  font-size: 3vw;
  font-weight: bold;
`

const RequestDescription = styled.div`
  width: 100%;
  max-width: 500px;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-size: 3vw;
  margin: 8px;
  white-space: normal;
  text-align: left;
`

const RequestImageGrid = styled.div`
  width: 100%;
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr;
`
const EmptyContainer = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const EmptyMessage = styled.div`
  font-size: 20px;
`


export default RequestTable