import {useTheme} from "@table-library/react-table-library/theme";
import {getTheme} from "@table-library/react-table-library/baseline";
import moment from "moment/moment";
import uuid from "react-uuid";
import {CompactTable} from "@table-library/react-table-library/compact";
import styled from "styled-components";
import {numberToCommaString} from "../../service/convert";
import MasterDetailButton from "../buttons/masterDetailButton";

const SettlementTable = ({nodes}) => {
    const data = {nodes}

    const theme = useTheme([getTheme(),
        {
            HeaderRow: `
             .th {
                text-align: center
             }
            `,
            Table: `
             --data-table-library_grid-template-columns: repeat(7, 1fr);
             text-align: center;
            `,
            BaseCell: `
              padding: 12px 0px;
            `
        }
    ])

    const columns = [
        {label: '견적명', renderCell: (item) => item.constructionType},
        {label: '공급가액', renderCell: (item) => `${numberToCommaString(Math.round(item.totalPrice))}`},
        {label: '합계', renderCell: (item) => `${numberToCommaString(Math.round(item.totalPrice))}`},
        {label: '매출증빙', renderCell: (item) => item.proofOfSales.length > 0 ?"있음":"없음"},
        {label: '발행여부', renderCell: (item) => item.issued?"예":"아니오"},
        {label: '빌딩몬 수수료', renderCell: (item) => `${numberToCommaString(Math.round(item.totalPrice * 0.2))}`},
        {label: '마스터 정보', renderCell: (item) => {
                return <MasterDetailButton key={uuid()} masters={item.masters} tableData={nodes} index={item.index}/>
            }},
    ];

    return (
        <div><CompactTable
            columns={columns}
            data={data}
            theme={theme}
            layout={{custom: true}}
        /></div>
    )
}

const ProgressStatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`

const ProgressStatus = styled.div`
  padding: 4px 8px;
  width: 50%;
  border-radius: 4px;
  text-align: center;
  background-color: ${props => {
    switch (props.status) {
        case "예정":
            return "orange"
        case "완료":
            return "blue"
        case "취소":
            return "red"
        default:
            return "white"
    }
}
};
  color: white;
`

export default SettlementTable