import styled from "styled-components";
import {BiSearch} from "react-icons/bi";

const SearchInput = () => {
    return (
        <MasterBoardSearchInputContainer>
            <SearchIcon/>
            <Input placeholder={"검색어를 입력해주세요..."}/>
        </MasterBoardSearchInputContainer>
    )
}



const MasterBoardSearchInputContainer = styled.div`
  width: 320px;
  display: flex;
  padding: 12px 20px;
  flex-direction: row;
  align-items: center;
  border-radius: 12px;
  background-color: gainsboro;
  
`

const SearchIcon = styled(BiSearch)`
  font-size: 24px;
  color: grey;
`

const Input = styled.input`
  padding: 8px;
  flex: 1;
  font-size: 20px;
  border: none;
  outline: none;
  color: #333;
  background-color: transparent;
`

export default SearchInput