import DatePicker, {registerLocale} from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css'
import ko from 'date-fns/locale/ko'
import styled from "styled-components";
import {BiCalendar} from "react-icons/bi";
import Spacer from "../spacer";
import moment from "moment";
registerLocale('ko', ko)

const DateInput = ({selected, onChange}) => {


    return <DatePicker locale="ko"  selected={selected} onChange={onChange} customInput={
        <StyledDatePicker>
            <BiCalendar size={32} color={"#333"}/>
            <Spacer width={20}/>
            <div>{moment(selected).format("YY/MM/DD")}</div>
        </StyledDatePicker>
    }

    />
}

const StyledDatePicker = styled.div`
  width: 200px;
  padding: 20px;
  background-color: white;
  height: 28px;
  font-size: 24px;
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-radius: 4px;
  box-shadow: rgba(50, 50, 93, 0.25) 0 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  color: black;
  cursor: pointer;
  &:focus {
    outline: 1px solid #3dcc4a;
  }
`

export default DateInput
