import {Container} from "../../components/containers/container";
import UnSettledListBoard from "../../components/boards/unSettledListBoard";

const Calculate = () => {
    return (
        <Container>
            <UnSettledListBoard/>
        </Container>
    )
}

export default Calculate