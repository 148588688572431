import styled from "styled-components";
import Spacer from "../spacer";
import {getCategoryBySubCategories} from "../../service/option";
import uuid from "react-uuid";

const MasterCreateMessage = ({data}) => {
    return (
        <Container>
            <Caution>
                &lt;주의&gt;<br/>
                입력하신 정보가 맞으신지<br/>
                한번 더 확인 부탁드리겠습니다.<br/>
                신규 기사님을 추가하시겠습니까?
            </Caution>
            <Spacer height={20}/>
            <Content>
                <ContentLabel>성함</ContentLabel>
                <ContentInfo>{data.name}</ContentInfo>
                <Spacer height={8}/>
                <ContentLabel>아이디</ContentLabel>
                <ContentInfo>{data.id}</ContentInfo>
                <Spacer height={8}/>
                <ContentLabel>전화번호</ContentLabel>
                <ContentInfo>{data.phoneNumber}</ContentInfo>
                <Spacer height={8}/>
                <ContentLabel>지점명</ContentLabel>
                <ContentInfo>{data.branchName}</ContentInfo>
                <Spacer height={8}/>
                <ContentLabel>지점주소</ContentLabel>
                <ContentInfo>{`${data.branchAddress} ${data.branchDetailAddress}`}</ContentInfo>
                <Spacer height={8}/>
                <ContentLabel>계좌번호</ContentLabel>
                <ContentInfo>{`${data.accountBank} ${data.accountNumber}`}</ContentInfo>
                <Spacer height={8}/>
                <ContentLabel>업종</ContentLabel>
                <ContentGrid>{getCategoryBySubCategories(data.selectedSubCategories).map((category) =>
                    <CategoryTag key={uuid()}>{category}</CategoryTag>)}</ContentGrid>
                <Spacer height={8}/>
                <ContentLabel>품목</ContentLabel>
                <Spacer height={8}/>
                <ContentGrid>{data.selectedSubCategories.map((subCategory) =>
                    <CategoryTag key={uuid()}>{subCategory}</CategoryTag>
                )}</ContentGrid>
            </Content>
            <Spacer height={20}/>
        </Container>
    )
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const Caution = styled.div`
  text-align: center;
  font-size: 20px;
`

const Content = styled.div`
  width: 88%;
  padding: 24px;
  font-size: 20px;
  box-shadow: rgba(50, 50, 93, 0.25) 0 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: scroll;
`

const ContentLabel = styled.div`
  font-size: 18px;
  margin-top: 4px;
  margin-bottom: 4px;
  font-weight: bold;
`
const ContentInfo = styled.div`
  font-size: 16px;
  margin-top: 4px;
  margin-bottom: 4px;
`

const ContentGrid = styled.div`
  width: 100%;
  padding-top: 4px;
  padding-bottom: 4px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 12px;
`
const CategoryTag = styled.div`
  padding: 8px 12px;
  font-size: 14px;
  font-weight: bold;
  border-radius: 4px;
  color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  background-color: #07C801;
`


export default MasterCreateMessage