import {Title} from "../title";
import Spacer from "../spacer";
import styled from "styled-components";

const StatusBoard = () => {
    return (
        <StatusBoardContainer>
            <Title>
                오늘 11월 17일 (금)
            </Title>
            <Row style={{width: "100%"}}>
                <InfoBox style={{background: "linear-gradient(to bottom, #b4e391 0%,#61c419 50%,#b4e391 100%)"}}>
                    <InfoTitle>월 매출</InfoTitle>
                    <Spacer height={20}/>
                    <InfoValue>500,000원</InfoValue>
                </InfoBox>
                <InfoBox style={{background: "linear-gradient(to bottom, #cb60b3 0%,#ad1283 50%,#de47ac 100%)"}}>
                    <InfoTitle>작업진행 중</InfoTitle>
                    <Spacer height={20}/>
                    <InfoValue>2건</InfoValue>
                </InfoBox>
                <InfoBox style={{background: "linear-gradient(to bottom, #b3dced 0%,#29b8e5 50%,#bce0ee 100%)"}}>
                    <InfoTitle>작업 예정</InfoTitle>
                    <Spacer height={20}/>
                    <InfoValue>4건</InfoValue>
                </InfoBox>
                <InfoBox style={{background: "linear-gradient(to bottom, #f6e6b4 0%,#ed9017 100%)"}}>
                    <InfoTitle>미정산</InfoTitle>
                    <Spacer height={20}/>
                    <InfoValue>1건</InfoValue>
                </InfoBox>
            </Row>

        </StatusBoardContainer>
    )
}

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 800px) {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 8px;
    flex-direction: column;
    justify-content: center;
  }
  
`

const StatusBoardContainer = styled.div`
  width: 94%;
  padding: 3vw;
  margin-bottom: 20px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background-color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
`

const InfoBox = styled.div`
  padding: 24px 3vw;
  flex: 1;
  border-radius: 12px;
  display: flex;
  height: 80px;
  margin: 0 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
`

const InfoTitle = styled.div`
  font-size: calc(1vw + 8px);
  color: white;
  font-weight: bold;
`

const InfoValue = styled.div`
  font-size: calc(1vw + 12px);
  color: white;
`


export default StatusBoard