import styled from "styled-components";

export const BoardContainer = styled.div`
  width: 94%;
  padding: 3vw;
  border-radius: 20px;
  min-height: 320px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
`