import styled from "styled-components";
import {IoMenuSharp} from "react-icons/io5";
import NavigationButton from "../buttons/navigationButton";

const MobileHeader = ({onClickLogoutButton, pathname}) => {
    return (
        <DashboardNavigation>
            <DashboardHeaderLogo src={"/images/logo.png"}/>

            <NavigationButton
                onClickLogoutButton={onClickLogoutButton}
                pathname={pathname}
                width={"100vw"}
                buttonChildren={<IconButton>
                    <IoMenuSharp size={32} />
                </IconButton>}

            />
        </DashboardNavigation>
    )
}




const DashboardHeaderLogo = styled.img`
  margin: 20px;
  width: 60px;
  @media (max-width: 800px) {
    width: 48px;
    margin: 8px 12px;
  }
`

const DashboardNavigation = styled.div`
  width: 100vw;
  background-color: #fff;
  box-shadow: rgba(240, 46, 170, 0.4) 0px 5px, rgba(240, 46, 170, 0.3) 0px 10px, rgba(240, 46, 170, 0.2) 0px 15px, rgba(240, 46, 170, 0.1) 0px 20px, rgba(240, 46, 170, 0.05) 0px 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  overflow: scroll;
  @media (min-width: 801px) {
    display: none;
  }
`

const IconButton = styled.button`
  padding: 8px;
  margin: 4px 12px 4px 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: white;
  border: 1px solid #eee;
  cursor: pointer;
`



export default MobileHeader


