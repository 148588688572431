import {create} from 'zustand'

export const useAuthStore = create((set) => ({
    user: null,
    setUser: (user) => set({user})
}))

export const useNotificationStore = create((set) =>( {
    notifications:[],
    setNotifications: (notifications) => set({notifications})
}))