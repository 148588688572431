import styled from "styled-components";
import {RiDeleteBinLine} from "react-icons/ri";

const DeleteButton = ({onClick}) => {
    return (
        <ButtonContainer onClick={onClick}>
            <DeleteIcon/>
        </ButtonContainer>
    )
}

const ButtonContainer = styled.button`
  padding: 20px;
  border-radius: 4px;
  background-color: red;
  box-shadow: rgba(50, 50, 93, 0.25) 0 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
`


const DeleteIcon = styled(RiDeleteBinLine)`
  font-size: 24px;
  color: white;
`
export default DeleteButton