import styled from "styled-components";
import {useState} from "react";
import 'react-datepicker/dist/react-datepicker.css'
import Spacer from "../spacer";
import {FaAngleDown, FaAngleUp} from "react-icons/fa";
import Swal from "sweetalert2";
import uuid from "react-uuid";
import withReactContent from "sweetalert2-react-content";
import './masterDrawer.css'
import PenaltyMessage from "../messages/penaltyMessage";
import {Title} from "../title";
import {convertContractDate} from "../../service/date";
import {showLoading, showSuccessMessage} from "../../service/swal";
import {collection, db, doc, updateDoc} from "../../service/auth/firebase";



const MasterDrawer = ({data, uid = ""}) => {
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date())
    const [penalty, setPenalty] = useState(data.penalty??0)
    const [startDatePickerIsOpen, setStartDatePickerIsOpen] = useState(false);
    const [endDatePickerIsOpen, setEndStartDatePickerIsOpen] = useState(false);
    const [fileListIsOpen, setFileListIsOpen] = useState(false)
    const [seeMore, setSeeMore] = useState(false)
    const [visibleFileCount, setVisibleFileCount] = useState(3);
    const [imageListIsOpen, setImageListIsOpen] = useState(false)
    const [penaltyButtonListIsOpen, setPenaltyListIsOpen] = useState(false)


    const handleStartDateClick = (e) => {
        e.preventDefault();
        setStartDatePickerIsOpen(!startDatePickerIsOpen);
    };

    const handleEndDateClick = (e) => {
        e.preventDefault();
        setEndStartDatePickerIsOpen(!endDatePickerIsOpen);
    };

    const toggleFileListDropdownIcon = () => {
        setFileListIsOpen(!fileListIsOpen)
    }


    const handleSeeMoreClick = () => {
        setSeeMore(!seeMore);
        setVisibleFileCount(seeMore ? 3 : Infinity); // If seeMore is true, show all items, else show 3 items
    };

    const toggleImageListDropdownIcon = () => {
        setImageListIsOpen(!imageListIsOpen)
    }

    const togglePenaltyButtonListDropdownIcon = () => {
        setPenaltyListIsOpen(!penaltyButtonListIsOpen)
    }

    const penaltyButtonOnClick = (index) => {

        withReactContent(Swal).fire({
            customClass: {
                actions: "actions",
                confirmButton: "confirm__button",
                cancelButton: "cancel__button",
            },
            width: 320,
            buttonsStyling: true,
            html: <PenaltyMessage score={index}/> ,
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "네",
            cancelButtonText: "아니오",
        }).then(async (result) => {
            if(result.isConfirmed){
                showLoading()
                let updatedPenalty = penalty + index - 3
                const ref = doc(collection(db, 'users'), uid)
                await updateDoc(ref, {
                    penalty: updatedPenalty
                })
                setPenalty(updatedPenalty)
                showSuccessMessage("패널티가 부여됬습니다")
            }

        })
    }



    return (
        <Container>
            {data !== {} && <>
                <Photo src={data.photoUrl !== "" ? data.photoUrl : "/images/logo.png"}/>
                <Spacer height={20}/>
                <Title>{data.displayName}</Title>
                <Divider/>
                <ProgressInfoContainer>
                    <DateInfo>
                        <ProgressInfoTitle>계약시작 날짜</ProgressInfoTitle>
                        <Spacer width={24}/>
                        {data.contracts && data.contracts.length !==0 && <div>{convertContractDate(data.contracts[data.contracts.length -1].startDate)}</div>}
                    </DateInfo>
                </ProgressInfoContainer>
                <ProgressInfoContainer>
                    <DateInfo>
                        <ProgressInfoTitle>계약종료 날짜</ProgressInfoTitle>
                        <Spacer width={24}/>
                        {data.contracts && data.contracts.length !==0 && <div>{convertContractDate(data.contracts[data.contracts.length -1].endDate)}</div>}
                    </DateInfo>
                </ProgressInfoContainer>
                {/*<ProgressInfoContainer onClick={toggleFileListDropdownIcon}>*/}
                {/*    <ProgressInfoTitle>*/}
                {/*        관련문서*/}
                {/*        <ProgressInfoSubtitle>(25개 파일)</ProgressInfoSubtitle>*/}
                {/*    </ProgressInfoTitle>*/}
                {/*    <DropdownIcon>*/}
                {/*        {fileListIsOpen ? <FaAngleUp/> :*/}
                {/*            <FaAngleDown/>}*/}
                {/*    </DropdownIcon>*/}
                {/*</ProgressInfoContainer>*/}
                {/*{fileListIsOpen && <>*/}
                {/*    <FileContainer>*/}
                {/*        {*/}
                {/*            [1, 2, 3, 4, 5].slice(0, visibleFileCount).map((item) => <File key={uuid()}>*/}
                {/*                <GrDocumentText/>*/}
                {/*                <Spacer width={8}/>*/}
                {/*                <FileName>견적서{item}.pdf</FileName>*/}
                {/*                <FileMoreButtonContainer>*/}
                {/*                    <MoreButton/>*/}
                {/*                </FileMoreButtonContainer>*/}
                {/*            </File>)*/}
                {/*        }*/}


                {/*    </FileContainer>*/}
                {/*    <FileListButtonContainer>*/}
                {/*        {!seeMore && <SeeMoreButton onClick={handleSeeMoreClick}>더보기</SeeMoreButton>}*/}
                {/*    </FileListButtonContainer>*/}
                {/*    <Spacer height={20}/>*/}
                {/*    <UploadButton>파일 업로드</UploadButton>*/}
                {/*</>}*/}
                {/*<Divider/>*/}
                {/*<ProgressInfoContainer onClick={toggleImageListDropdownIcon}>*/}
                {/*    <ProgressInfoTitle>*/}
                {/*        사진*/}
                {/*        <ProgressInfoSubtitle>(25개 파일)</ProgressInfoSubtitle>*/}
                {/*    </ProgressInfoTitle>*/}
                {/*    <DropdownIcon>*/}
                {/*        {imageListIsOpen ? <FaAngleUp/> : <FaAngleDown/>}*/}
                {/*    </DropdownIcon>*/}
                {/*</ProgressInfoContainer>*/}
                {/*{*/}
                {/*    imageListIsOpen &&*/}
                {/*    <>*/}
                {/*        <GridContainer>*/}
                {/*            {[1, 2, 3, 4, 5, 6].map(() => <Image key={uuid()}/>)}*/}
                {/*        </GridContainer>*/}
                {/*        <Spacer height={20}/>*/}
                {/*        <UploadButton>사진 업로드</UploadButton>*/}
                {/*    </>*/}
                {/*}*/}
                <Divider/>
                <ProgressInfoContainer onClick={togglePenaltyButtonListDropdownIcon}>
                    <ProgressInfoTitle>
                        패널티 (현재 {penalty}점)
                    </ProgressInfoTitle>
                    <DropdownIcon>
                        {penaltyButtonListIsOpen ? <FaAngleUp/> : <FaAngleDown/>}
                    </DropdownIcon>
                </ProgressInfoContainer>
                {
                    penaltyButtonListIsOpen &&
                    <>
                        <GridContainer>
                            {["고객이탈", "해결 못함", "A/S 불가", "매칭 후 취소", "컴플레인 없음", "고객만족"].map((item, i) => <PenaltyButton
                               key={uuid()}  index={i}  onClick={() => penaltyButtonOnClick(i)}>{item}</PenaltyButton>)}
                        </GridContainer>
                    </>
                }
            </>}
        </Container>
    )
}



const Container = styled.div`
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
`


const Photo = styled.img`
  width: 120px;
  height: 120px;
  box-shadow: rgba(0, 0, 0, 0.05) 0 6px 24px 0, rgba(0, 0, 0, 0.08) 0 0 0 1px;
  border-radius: 300px;
  background-size: cover;
`

const ProgressInfoContainer = styled.div`
  width: 94%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 4px 0;
  cursor: pointer;
`

const DateInfo = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-top: 8px;
  margin-bottom: 8px;
  color: black;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const ProgressInfoTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: black;
  padding-top: 4px;
  padding-left: 12px;
`

const GridContainer = styled.div`
  width: 88%;
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 12px;
  margin-bottom: 20px;
`

const PenaltyButton = styled.div`
  box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
  aspect-ratio: 1/1;
  border-radius: 12px;
  font-size: 20px;
  color: white;
  font-weight: bold;
  background-color: ${(props) => {
      switch (props.index){
        case 0:
            return "#CC3201"
        case 1:
            return "#FF9966"
        case 2:
            return "#FFCC01"
        case 3:
          return "#A6D96A"
        case 4:
            return "#99CC33"
        case 5:
            return "#339900"
        default:
            return "white"
      }
  }};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

const DropdownIcon = styled.div`
  padding-top: 8px;
  padding-right: 10px;
  font-size: 24px;
  cursor: pointer;
  color: #3dcc4a;
`

const Divider = styled.div`
  width: 94%;
  height: 1px;
  background-color: #eee;
  margin-top: 12px;
  margin-bottom: 12px;
  position: sticky;
`


export default MasterDrawer