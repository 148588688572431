import {Route, Routes} from "react-router-dom";
import Login from "./pages/login";
import Home from "./pages/dashboard/home";
import DashboardLayout from "./components/layout/dashboardLayout";
import Error from "./pages/error";
import Master from "./pages/dashboard/master";
import Progress from "./pages/dashboard/progress";
import ProgressCreate from "./pages/dashboard/progressCreate";
import MasterCreate from "./pages/dashboard/masterCreate";
import {useEffect} from "react";
import {
    auth,
    collection,
    db,
    doc,
    getDoc,
    onSnapshot,
    serverTimestamp,
    setDoc,
    updateDoc, where
} from "./service/auth/firebase";
import {useAuthStore} from "./store/store";
import {query} from "firebase/firestore";
import {toast} from "react-toastify";
import MasterUpdate from "./pages/dashboard/masterUpdate";
import Calculate from "./pages/dashboard/calculate";



const Router = () => {
    const {setUser, user} = useAuthStore()

    // firebase auth 기반 로그인 인증 확인
    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(fbUser => {
            if (fbUser != null) {
                const userDocRef = doc(db, 'users', fbUser.uid)
                getDoc(userDocRef).then((doc) => {
                    if (!doc.exists()) {
                        setDoc(userDocRef, {
                            displayName: fbUser.displayName??"",
                            photoUrl: fbUser.photoURL??"",
                            phoneNumber: fbUser.phoneNumber??"",
                            email: fbUser.email,
                            type: "admin",
                            accountStatus: "active",
                            lastLogin: serverTimestamp(),
                            createdAt: serverTimestamp()
                        }).then(() => setUser(doc.data()));
                    } else if (doc.exists) {
                        // 어드민 타입 체크
                        if (doc.data().type !== "admin") {
                            setUser(null)
                        } else {
                            // 로그인시 자동로그인 시간 DB 기록
                            updateDoc(userDocRef, {
                                lastLogin: serverTimestamp(),
                                accountStatus: "active",
                                email: fbUser.email,
                            }).then(() => setUser(doc.data()))
                                .catch((error) => setUser(null))
                        }
                    }
                });
            }else{
                setUser(null)

            }
        });
        return () => {
            unsubscribe()
        }
    }, [setUser]);


    // 기사 미배정 && 배정 완료 요청건 알림
    useEffect(() => {
        let initialLoad = true
        const q = query(collection(db, 'requests'), where('status', '==' , "기사 미배정"))
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            let changes = 0
            let removedChanges = 0
            if(user!= null){
                querySnapshot.docChanges().forEach((change) => {
                    if(change.type === "added"){
                        changes++
                    }else if(change.type === "removed"){
                        removedChanges++
                    }
                })
                if(initialLoad){
                    if(changes>0){
                        toast(`${changes}건의 기사 미배정 문의`, {
                            hideProgressBar: true,
                            autoClose: 2000
                        })
                    }
                    initialLoad = false
                    changes = 0
                }else{
                    if(changes>0){
                        toast("새로운 기사 미배정 문의")
                    }
                    if(removedChanges > 0){
                        toast(`기사 배정완료`)
                    }
                }

            }
        })

        return () => {
            unsubscribe()
        }

    }, [user]);




    return (
        <Routes>
            <Route index path={'/'} element={<Login/>}/>
            <Route path={"dashboard"} element={<DashboardLayout/>}>
                <Route index element={<Home/>}/>
                <Route path={'progress'} element={<Progress/>}/>
                <Route path={'progress/create'} element={<ProgressCreate/>}/>
                <Route path={"master"} element={<Master/>}/>
                <Route path={'master/create'} element={<MasterCreate/>}/>
                <Route path={'master/:id'} element={<MasterUpdate/>}/>
                <Route path={'calculate'} element={<Calculate/>}/>
            </Route>
            <Route path={"*"} element={<Error/>}/>
        </Routes>
    )
}

export default Router