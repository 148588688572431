import {Navigate, Outlet, useLocation} from "react-router-dom";
import styled from "styled-components";
import {auth, signOut} from "../../service/auth/firebase";
import {ToastContainer} from "react-toastify";
import {useAuthStore} from "../../store/store";
import DesktopHeader from "../headers/desktop";
import MobileHeader from "../headers/mobile";
import {useEffect} from "react";

const DashboardLayout = () => {
    const {user} = useAuthStore()
    const location = useLocation()
    const {pathname} = location


    const onClickLogoutButton = async () => {
        try {
            await signOut(auth)
        } catch (error) {
            console.log(error.message)
        }

    }

    useEffect(() => {
        const setVhVariable = () => {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty("--vh", `${vh}px`);
        };

        // 초기 마운트 시 설정
        setVhVariable();

        // 리사이즈 이벤트 핸들러 등록
        window.addEventListener("resize", () => {
            setVhVariable();
        });

        // 언마운트 시 이벤트 핸들러 제거
        return () => {
            window.removeEventListener("resize", setVhVariable);
        };
    }, []);


    if (!user) {
        return <Navigate to={'/'}/>
    }

    return (
        <DashboardContainer>
            <ToastContainer/>
            <DesktopHeader onClickLogoutButton={onClickLogoutButton} pathname={pathname}/>
            <MobileHeader onClickLogoutButton={onClickLogoutButton} pathname={pathname}/>
            <DashboardBody>
                <Outlet/>
            </DashboardBody>
        </DashboardContainer>
    )
}

const DashboardContainer = styled.div`
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: row;
  overflow: hidden;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`


const DashboardBody = styled.div`
  flex: 1;
  overflow-y: scroll;
  overflow-x: unset;
  background-color: #eee;
`


export default DashboardLayout


