import {CompactTable} from '@table-library/react-table-library/compact';
import {useTheme} from '@table-library/react-table-library/theme';
import {getTheme} from '@table-library/react-table-library/baseline';
import {VscCalendar} from "react-icons/vsc";
import DrawerButton from "../buttons/drawerButton";
import MasterScheduleDrawer from "../drawers/masterScheduleDrawer";
import {getCategoryBySubCategories} from "../../service/option";
import uuid from "react-uuid";
import ShowDetailButton from "../buttons/showDetailButton";
import {useNavigate} from "react-router-dom";





const MasterTable = ({nodes}) => {
    const data = {nodes}
    const navigate = useNavigate()

    const theme = useTheme([getTheme(),
        {
            HeaderRow: `
             .th {
                text-align: center
             }
            `,
            Table: `
             --data-table-library_grid-template-columns: 100px 240px 100px repeat(5, 1fr) 150px;
             text-align: center;
            `,
            Row: `
                cursor:pointer   
         
            `,
            BaseCell:`
              padding: 12px 0px;
            `
        }
    ])

    const columns = [
        {label: '이름', renderCell: (item) => item.displayName},
        {label: '이메일', renderCell: (item) => item.email},
        {label: '회원유형', renderCell: (item) => `${item.subscriptionType}`},
        {label: '지점명', renderCell: (item) => item.branchName,},
        {label: '업종', renderCell: (item) => getCategoryBySubCategories(item.tags)},
        {label: '매치횟수', renderCell: (item) => `${item.match}회`},
        {label: '패널티', renderCell: (item) => `${item.penalty}점`},
        {label: '스케줄', renderCell: (item) =>
                <DrawerButton
                    key={uuid()}
                    width={800}
                    buttonChildren={<VscCalendar size={32} color={"black"}/>}
                    drawerChildren={<MasterScheduleDrawer/>}/>
        },

        {label: '', renderCell: (item) => <ShowDetailButton onClick={() => navigate(`${item.id}`)}/>}
    ];




    return <div><CompactTable
        columns={columns}
        data={data}
        theme={theme}
        layout={{custom: true}}
    /></div>;
}


export default MasterTable