
// 미확인 견적 보드
import {Title} from "../title";
import UnconfirmedRequestTable from "../tables/requestTable";
import {TableContainer} from "../containers/tableContainer";

const RequestBoard =() => {
    return (
        <TableContainer>
            <Title>기사 미배정 요청</Title>
            <UnconfirmedRequestTable/>
        </TableContainer>
    )
}


export default RequestBoard