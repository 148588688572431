import StatusBoard from "../../components/boards/statusBoard";
import RequestBoard from "../../components/boards/requestBoard";
import {Container} from "../../components/containers/container";

const Home = () => {
    return (
        <Container>
            <StatusBoard/>
            <RequestBoard/>
        </Container>
    )
}




export default Home