import styled from "styled-components";
import Lottie from "lottie-react";
import notFound from '../json/not-found.json'


const Error = () => {

    return (
        <Container>
            <Lottie animationData={notFound} width={40} height={40}/>
            <Message>페이지를 찾을 수 없습니다.</Message>
        </Container>
    )

}

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Message = styled.div`
  font-size: 32px;
  font-weight: bold;
  color: black;
`





export default Error
