import styled from "styled-components";

const ShowDetailButton = ({onClick}) => {

    return (
        <Button onClick={onClick}>
            상세보기
        </Button>
    )
}


const Button = styled.button`
    background-color: #07CA08;
  border: none;
  border-radius: 4px;
  padding: 8px;
  color: white;
  cursor: pointer;
`

export default ShowDetailButton