import styled from "styled-components";

const Message = ({children}) => {
    return (
        <div>
            <Text>{children}</Text>
        </div>
    )
}


const Text = styled.div`
    line-height: 1.7;
  font-weight: bold;
  font-size: 16px;
`
export default Message