import styled from "styled-components";
import DaumPostcodeEmbed from "react-daum-postcode";
import React from "react";
import Modal from 'react-modal'
import {IoMdClose} from "react-icons/io";

const FindAddressButton = ({onComplete, modalIsOpen, closeModal, onClick}) => {


    return (
        <>
            <Button onClick={onClick}>주소찾기</Button>
            <Modal
                style={{
                    overlay: {
                        zIndex: 9999
                    },
                    content: {
                        height: 600,
                        top: "50%", // Center vertically
                        left: "50%", // Center horizontally
                        transform: "translate(-50%, -50%)", // Center the modal
                        maxWidth: "94vw",
                        minWidth: 300,
                        maxHeight: "90vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }
                }}
                ariaHideApp={false}
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                onBackButtonPress={closeModal}
            >
                <Column>
                    <CloseButtonContainer onClick={closeModal}>
                        <IoMdClose size={32}/>
                    </CloseButtonContainer>
                    <DaumPostcodeEmbed style={{width: "100%", maxWidth: "90vw",height: "500px"}} onComplete={onComplete}/>
                </Column>


            </Modal>
        </>
    )
}

const Button = styled.button`
  border: none;
  background-color:#07C801;
  padding: 20px;
  font-size: 20px;
  cursor: pointer;
  border-radius: 4px;
  color: white;
`

const Column = styled.div`
  width: 100%;
    display: flex;
  flex-direction: column;
`

const CloseButtonContainer = styled.div`
  position: relative;
  top: -40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  cursor: pointer;

`

export default FindAddressButton