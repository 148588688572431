import {CompactTable} from '@table-library/react-table-library/compact';
import {useTheme} from '@table-library/react-table-library/theme';
import {getTheme} from '@table-library/react-table-library/baseline';
import moment from 'moment'
import styled from "styled-components";
import uuid from "react-uuid";



const ProgressTable = ({nodes}) => {
    const data = {nodes}

    const theme = useTheme([getTheme(),
        {
            HeaderRow: `
             .th {
                text-align: center
             }
            `,
            Table: `
             --data-table-library_grid-template-columns: 100px 120px 300px repeat(5, 1fr);
             text-align: center;
            `,
            BaseCell: `
              padding: 12px 0px;
            `
        }
    ])

    const columns = [
        {label: '문의날짜', renderCell: (item) => moment(item.requestDate.toDate()).format("YY/MM/DD")},
        {label: '고객성함(업체)', renderCell: (item) => item.customerName},
        {label: '주소', renderCell: (item) => item.address},
        {label: '품목', renderCell: (item) => item.constructionType,},
        {label: '담당 기사', renderCell: (item) => item.master},
        {label: '기사 번호', renderCell: (item) => item.tel},
        {label: '작업상태',
            renderCell: (item) => <ProgressStatusContainer key={uuid()}><ProgressStatus
                status={item.status}>{item.status}</ProgressStatus></ProgressStatusContainer>
        },
        {label: '비용(vat포함)', renderCell: (item) => item.cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + "원"},
    ];



    return <div><CompactTable
        columns={columns}
        data={data}
        theme={theme}
        layout={{custom: true}}
    /></div>;
}


const ProgressStatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`

const ProgressStatus = styled.div`
  padding: 4px 8px;
  width: 50%;
  border-radius: 4px;
  text-align: center;
  background-color: ${props => {
    switch (props.status) {
        case "예정":
            return "orange"
        case "완료":
            return "blue"
        case "취소":
            return "red"
        default:
            return "white"
    }
}
};
  color: white;
`

export default ProgressTable