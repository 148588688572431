import {useEffect, useState} from "react";
import Drawer from 'react-modern-drawer'
import styled from "styled-components";
import {IoClose} from "react-icons/io5";
import {FiLogOut} from "react-icons/fi";
import {Link, useNavigate} from "react-router-dom";

const NavigationButton = ({width, buttonChildren, onClickLogoutButton, pathname}) => {
    const [isOpen, setIsOpen] = useState(false)
    const navigate = useNavigate()
    const toggleDrawer = () => {
        setIsOpen(!isOpen)
    }

    const closeDrawerIfWideScreen = () => {
        // 화면이 가로 800px보다 크면 Drawer를 닫습니다.
        if (window.innerWidth > 800 && isOpen) {
            setIsOpen(false);
        }
    };

    const toggleNavigationLink = (route) => {
        toggleDrawer()
        navigate(route)
    }

    useEffect(() => {
        // 윈도우 리사이즈 이벤트를 감지하여 Drawer를 닫습니다.
        window.addEventListener("resize", closeDrawerIfWideScreen);

        // 컴포넌트가 언마운트되면 이벤트 리스너를 제거합니다.
        return () => {
            window.removeEventListener("resize", closeDrawerIfWideScreen);
        };
    }, [isOpen]); // isOpen이 변경될 때마다 useEffect를 실행



    return (
        <div>
            <ButtonContainer onClick={toggleDrawer}>
                {buttonChildren}
            </ButtonContainer>
            <Drawer
                open={isOpen}
                onClose={toggleDrawer}
                direction='right'
                size={width}
                lockBackgroundScroll={true}
            >
                <CloseButtonContainer>
                    <CloseButton onClick={toggleDrawer}>
                        <IoClose />
                    </CloseButton>
                </CloseButtonContainer>
                <ContentContainer>
                    <NavigationLink onClick={() => toggleNavigationLink('/dashboard')} selected={pathname === "/dashboard"} to={''}>대시보드</NavigationLink>
                    <NavigationLink onClick={() => toggleNavigationLink('/dashboard/progress')} selected={pathname.includes("progress")} to={'progress'}>작업</NavigationLink>
                    <NavigationLink onClick={() => toggleNavigationLink('/dashboard/master')} selected={pathname.includes("master")} to={'master'}>기사</NavigationLink>
                    <NavigationLink onClick={() => toggleNavigationLink('/dashboard/master')} selected={pathname.includes("calculate")} to={'calculate'}>정산</NavigationLink>
                    <LogoutButton onClick={() => {
                        toggleDrawer()
                        onClickLogoutButton()}}>
                        로그아웃
                    </LogoutButton>
                </ContentContainer>
            </Drawer>
        </div>
    )
}

const ButtonContainer = styled.div`
  cursor: pointer;
`

const CloseButtonContainer = styled.div`
  width: 96%;
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`

const CloseButton = styled.button`
  border: 1px solid #eee;
  width: 48px;
  height: 48px;
  border-radius: 100px;
  background-color: white;
  font-size: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  cursor: pointer;
`

const ContentContainer = styled.div`
  height: calc(100vh - 68px);
  flex: 1;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
`

const NavigationLink = styled.div`
  cursor: pointer;
  color: ${props => props.selected ? "#07ca03" : "grey"};
  font-size: calc(14px + 0.4vw);
  padding: 5vw;
  margin-top: 8px;
  margin-bottom: 8px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    font-weight: bold;
    color: #07ca03;
  }
`

const LogoutButton = styled.div`
  color: grey;
  padding: 5vw;
  font-size: calc(14px + 0.4vw);
  width: 100%;
  height: 60px;
  margin-top: 8px;
  margin-bottom: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    font-weight: bold;
    color: #07ca03;
  }
`

export default NavigationButton

