import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import React from "react";

export const showSuccessMessage = (message) => {
    Swal.fire({
        text: `${message}`,
        width: "50vw",
        icon: "success",
        customClass: {
            actions: "actions",
            confirmButton: "confirm__button",
            cancelButton: "cancel__button",
        },
        confirmButtonText: "확인",

    })
}

export const showErrorMessage = (message) => {
    Swal.fire({
        text: `${message}`,
        icon: "error",
        customClass: {
            actions: "actions",
            confirmButton:"confirm__red__button"
        },
        confirmButtonText: "확인"
    });
}

export const showLoading = () => {
    withReactContent(Swal).fire({
        customClass: "unset",
        html: <span className={"loader"}></span>,
        showConfirmButton: false,
        allowOutsideClick: false,
    })
}

